import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import VueMeta from "vue-meta";
import Home from "../views/Home.vue";
import ColorPicker from "../views/ColorPicker.vue";
import UuidGenerator from "../views/UuidGenerator.vue";
import Myip from "@/views/Myip.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "meenzen.net",
    },
    component: Home,
  },
  {
    path: "/tools/color-picker",
    name: "color-picker",
    meta: {
      title: "Color Picker",
    },
    component: ColorPicker,
  },
  {
    path: "/tools/uuid-generator",
    name: "uuid-generator",
    meta: {
      title: "UUID Generator",
    },
    component: UuidGenerator,
  },
  {
    path: "/tools/myip",
    name: "myip",
    meta: {
      title: "What's my IP?",
    },
    component: Myip,
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "About",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    meta: {
      title: "404",
    },
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// duplicate navigation hack
const superPush = VueRouter.prototype.push;
VueRouter.prototype.push = async function push(
  location: RawLocation
): Promise<Route> {
  try {
    return await superPush.bind(this)(location);
  } catch (e) {
    if (e?.name === "NavigationDuplicated") {
      console.warn(e.message);
      return e;
    } else {
      throw e;
    }
  }
};

export default router;
