





















import Vue from "vue";

interface Color {
  alpha: number;
  hex: string;
  hexa: string;
  hsla: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
  hsva: {
    h: number;
    s: number;
    v: number;
    a: number;
  };
  hue: number;
  rgba: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
}

export default Vue.extend({
  metaInfo: {
    title: "Color Picker",
  },
  data(): {
    picker: Color | null;
  } {
    return {
      picker: null,
    };
  },
  methods: {
    getHsl(withAlpha: boolean): string {
      if (!this.picker) {
        return "";
      }
      const hsla = this.picker.hsla;
      const h = Math.round(hsla.h);
      const s = Math.round(hsla.s * 100);
      const l = Math.round(hsla.l * 100);
      if (!withAlpha) {
        return `hsl(${h}, ${s}%, ${l}%)`;
      }
      const a = parseFloat(hsla.a.toFixed(2));
      return `hsla(${h}, ${s}%, ${l}%, ${a})`;
    },
    getRgb(withAlpha: boolean): string {
      if (!this.picker) {
        return "";
      }
      const rgba = this.picker.rgba;
      const r = Math.round(rgba.r);
      const g = Math.round(rgba.g);
      const b = Math.round(rgba.b);
      if (!withAlpha) {
        return `rgb(${r}, ${g}, ${b})`;
      }
      const a = parseFloat(rgba.a.toFixed(2));
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
  },
});
