






















import Vue from "vue";

export default Vue.extend({
  name: "Home",
  metaInfo: {
    title: "Home",
  },
  data: () => ({
    webServices: [
      {
        title: "Chat",
        subTitle: "Matrix Homeserver",
        image: require("../assets/matrix-logo-white.svg"),
        buttonText: "Element Client",
        href: "https://element.meenzen.net",
      },
      {
        title: "Cloud",
        subTitle: "Nextcloud Server",
        image: require("../assets/nextcloud-logo.svg"),
        buttonText: "Nextcloud",
        href: "https://cloud.meenzen.net",
      },
      {
        title: "Email",
        subTitle: "Mailcow Server",
        image: require("../assets/mailcow-logo.svg"),
        buttonText: "Webmail",
        href: "https://mail.meenzen.net/SOGo/",
      },
    ],
  }),
});
