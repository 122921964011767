



















import Vue from "vue";
import { IpAddress } from "../../functions-disabled/models/IpAddress";

export default Vue.extend({
  metaInfo: {
    title: "What's my IP?",
  },
  data(): {
    loading: boolean;
    ipAddress: IpAddress;
    error: boolean;
  } {
    return {
      loading: false,
      ipAddress: { ip: "loading...", country: "loading..." },
      error: false,
    };
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.getIp();
      },
      { immediate: true },
    );
  },
  methods: {
    async getIp() {
      this.error = false;
      this.loading = true;

      const response = await fetch("/api/myip").catch(() => {
        this.error = true;
        return;
      });

      if (!response || !response.ok) {
        this.ipAddress = { ip: "unknown", country: "unknown" };
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.loading = false;
        return;
      }

      const ip = await response.json<IpAddress>().catch(() => {
        this.error = true;
      });

      if (!ip) {
        this.ipAddress = { ip: "unknown", country: "unknown" };
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.loading = false;
        return;
      }

      this.ipAddress = ip;

      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.loading = false;
    },
  },
});
