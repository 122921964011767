
















































































import Vue from "vue";

interface NavLink {
  title: string;
  icon: string;
  action: () => void;
}

interface FooterLink {
  icon: string;
  href: string;
}

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: "Web App",
    titleTemplate: "%s | meenzen.net",
  },

  data(): {
    drawer: boolean;

    navLinks: Array<NavLink>;
    footerLinks: Array<FooterLink>;

    swUpdateFound: boolean;
    swCached: boolean;

    // service worker update
    refreshing: boolean;
    registration: ServiceWorkerRegistration | undefined;
    updateExists: boolean;
  } {
    return {
      drawer: false,

      navLinks: [
        {
          title: "Home",
          icon: "mdi-home",
          action: () => this.$router.push("/"),
        },
        {
          title: "Color Picker",
          icon: "mdi-palette",
          action: () => this.$router.push("/tools/color-picker"),
        },
        {
          title: "UUID Generator",
          icon: "mdi-identifier",
          action: () => this.$router.push("/tools/uuid-generator"),
        },
        {
          title: "What's my IP?",
          icon: "mdi-ip",
          action: () => this.$router.push("/tools/myip"),
        },
      ],

      footerLinks: [
        { icon: "mdi-github", href: "https://github.com/meenzen" },
        { icon: "mdi-gitlab", href: "https://gitlab.com/meenzen" },
        {
          icon: "mdi-message-text",
          href: "https://matrix.to/#/@samuel:meenzen.net",
        },
      ],

      swUpdateFound: false,
      swCached: false,

      // service worker update
      refreshing: false,
      registration: undefined,
      updateExists: false,
    };
  },

  // https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
  created() {
    // Notify the user when app is downloading content
    document.addEventListener(
      "swUpdatefound",
      () => (this.swUpdateFound = true),
      {
        once: true,
      }
    );

    // Notify the user when app is ready for offline use
    document.addEventListener("swCached", () => (this.swCached = true), {
      once: true,
    });

    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    updateAvailable(event: any) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});
