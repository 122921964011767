






















































































import Vue from "vue";

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

interface Separator {
  description: string;
  value: string;
}

const NewlineSeparator: Separator = {
  description: "Newline",
  value: "\n",
};
const SpaceSeparator: Separator = {
  description: "Space",
  value: " ",
};
const CommaSeparator: Separator = {
  description: "Comma",
  value: ",",
};
const CommaSpaceSeparator: Separator = {
  description: "Comma + Space",
  value: ", ",
};
const CommaNewlineSeparator: Separator = {
  description: "Comma + Newline",
  value: ",\n",
};
const SemicolonSeparator: Separator = {
  description: "Semicolon",
  value: ";",
};
const SemicolonSpaceSeparator: Separator = {
  description: "Semicolon + Space",
  value: "; ",
};
const SemicolonNewlineSeparator: Separator = {
  description: "Semicolon + Newline",
  value: ";\n",
};

export default Vue.extend({
  metaInfo: {
    title: "UUID Generator",
  },
  data() {
    return {
      uuid: "",
      uuids: "",
      multiple: 10,
      optionsDialog: false,
      separators: [
        NewlineSeparator,
        SpaceSeparator,
        CommaSeparator,
        CommaSpaceSeparator,
        CommaNewlineSeparator,
        SemicolonSeparator,
        SemicolonSpaceSeparator,
        SemicolonNewlineSeparator,
      ],
      separator: NewlineSeparator,
      quotes: false,
    };
  },
  created() {
    this.generate();
    this.generateMultiple();
  },
  methods: {
    getUuid(): string {
      let uuid = "";
      try {
        uuid = crypto.randomUUID();
      } catch (error) {
        this.uuid = "Could not generate UUID";
      }

      return uuid;
    },
    generate() {
      this.uuid = this.getUuid();
    },
    generateMultiple() {
      let uuids = "";
      for (let i = 0; i < this.multiple; i++) {
        let uuid = this.getUuid();
        if (this.quotes) {
          uuid = `"${uuid}"`;
        }
        uuids += uuid + this.separator.value;
      }
      this.uuids = uuids.trim();
    },
  },
});
